import { Field, ProductVariant } from '@commerce/types/product'
import { SelectedOptions } from '@components/product/helpers'
import { Document } from '@prismicio/client/types/documents'
import PrismicDOM from 'prismic-dom'
import * as R from 'ramda'
import { linkResolver } from './prismic'
import { DispatchContext } from './types/general'
export const MULTI_OPTION_TYPE = 'multi-options'
export const NO_OPTION_TYPE = 'no-options'
export const AUTO_SELECT_OFF = [
  'european-pillowcase',
  'pillow-insert',
  'bath-sheet',
  'hand-towel',
  'face-towels',
  'bath-mat',
]
export const COMPONENTS = [
  {
    type: 'european-pillowcase',
    label: 'European Pillowcase',
    optionType: NO_OPTION_TYPE,
    optionLabel: 'Euro',
    qty: [1, 2, 3],
  },
  {
    type: 'pillow-insert',
    label: 'European Pillowcase',
    optionType: NO_OPTION_TYPE,
    optionLabel: 'Euro pillow',
    qty: [1, 2, 3],
  },
  {
    type: 'pillowcases',
    label: 'Pillowcases',
    optionType: MULTI_OPTION_TYPE,
    excludeQtyConversion: ['Standard Size / Single', 'King Size / Single'],
    qty: [1, 2],
  },
  {
    type: 'stocking',
    label: 'Stocking',
    optionType: NO_OPTION_TYPE,
  },
  {
    type: 'bath-sheet',
    optionType: NO_OPTION_TYPE,
    qty: [1, 2, 3, 4],
  },
  {
    type: 'hand-towel',
    optionType: NO_OPTION_TYPE,
    qty: [1, 2, 3, 4],
  },
  {
    type: 'bath-mat',
    optionType: NO_OPTION_TYPE,
    qty: [1, 2, 3],
  },
  {
    type: 'face-towels',
    optionType: NO_OPTION_TYPE,
    qty: [1, 2, 3],
  },
]
export const RANGE_TAG_PREFIX = 'range:'
export const COLOUR_TAG_PREFIX = 'colour:'
export const TYPE_TAG_PREFIX = 'type:'
export const RANGE_ICON_PREFIX = 'icon:'
export const PRODUCT_CALLOUT_PREFIX = 'tag:'
export const CATEGORY_TAG_PREFIX = 'collection:'
export const PARENT_TAG_PREFIX = 'parent:'
export const COLOURWAY_TAG_PREFIX = 'colourway:'
export const FABRIC_TAG_PREFIX = 'fabric:'

export const getSiblingColourTagByParent = (tags: string[] = []) => {
  const parentTags = tags.filter((item) => item.startsWith(PARENT_TAG_PREFIX)) as string[]
  return parentTags.length === 1 ? parentTags[0] : parentTags.length > 1 ? parentTags : null
}

export const normalizeSizingRules = (doc?: Document) => {
  if (!doc) null
  const rules: any = {}

  const slices = doc?.data.body

  if (Array.isArray(slices) && slices.length !== 0) {
    slices.map((slice: any) => {
      const { product, secondary_product, size_selected } = slice.primary
      if (!product || !secondary_product || !size_selected) return null
      if (product.trim() === '' || secondary_product.trim() === '' || size_selected.trim() === '') return null
      const productSlug = slugify(product)
      const sizeSelectedSlug = slugify(size_selected)
      const secondaryProductSlug = slugify(secondary_product)

      const secondaryProductSettings: any = {}

      if (!rules?.[productSlug]) rules[productSlug] = { label: product }

      secondaryProductSettings[secondaryProductSlug] = {
        label: secondary_product,
        sizes: slice.items?.map((s: any) => ({ label: s?.size_to_show, selected: s.selected || false })),
      }
      const sizeSelectionSettings = {
        ...rules[productSlug]?.[sizeSelectedSlug],
        ...secondaryProductSettings,
      }

      rules[productSlug][sizeSelectedSlug] = sizeSelectionSettings
    })
  }

  return rules
}

export const normalizeDispatchRules = (doc: Document): DispatchContext => {
  const { data } = doc

  return {
    weekdayMessage: PrismicDOM.RichText.asHtml(data.dispatch_weekday_message, linkResolver),
    weekendMessage: PrismicDOM.RichText.asHtml(data.dispatch_weekend_message, linkResolver),
    countDown: data.dispatch_countdown_message_enabled,
    countDownMessage: PrismicDOM.RichText.asHtml(data.dispatch_countdown_message, linkResolver),
    countDownStartTime: data.dispatch_countdown_start_time,
    countDownEndTime: data.dispatch_countdown_end_time,
    countDownTimeZone: data.dispatch_countdown_timezone,
    days: {
      '1': data.dispatch_countdown_monday,
      '2': data.dispatch_countdown_tuesday,
      '3': data.dispatch_countdown_wednesdaym,
      '4': data.dispatch_countdown_thursday,
      '5': data.dispatch_countdown_friday,
      '6': data.dispatch_countdown_saturday,
      '7': data.dispatch_countdown_sunday,
    },
  }
}

export const getMetafieldsValueByKey = (metafields: Field[], key: string, parse = false) => {
  const item: any = metafields && R.find(R.propEq('key', key))(metafields)

  if (parse) {
    return item?.value ? JSON.parse(item.value) : []
  }

  return item?.value
}

export const slugify = (text: string) =>
  text
    .toLocaleLowerCase()
    .trim()
    .replace(/[^\w\- ]+/g, '')
    .replace(/ +/g, '-')
    .replace(/\-\-+/g, '-')

export const getSelectedVariant = (selectedOptions: SelectedOptions, variants: ProductVariant[]) => {
  const selectedOptionVariantId = Object.keys(selectedOptions)?.[0]
  return variants.filter((v) => v.id === selectedOptionVariantId)?.[0]
}

export const metafieldListForGraphQl = `[${[
  '{ namespace: "my_fields", key: "bundle_components" }',
  '{ namespace: "my_fields", key: "bundle_sizing_rules" }',
  '{ namespace: "my_fields", key: "pdp_copy" }',
  '{ namespace: "my_fields", key: "breadcrumbs" }',
  '{ namespace: "my_fields", key: "prices" }',
  '{ namespace: "my_fields", key: "bundle_sizes" }',
  '{ namespace: "my_fields", key: "complete_the_look_components" }',
  '{ namespace: "my_fields", key: "silimarproducts" }',
  '{ namespace: "my_fields", key: "promo_tile" }',
  '{ namespace: "my_fields", key: "available_countries" }',
].join(',')}]`

export const metafieldListForBuilderGraphQl = `[${[
  '{ namespace: "builderConfiguration", key: "modelARMaterial" }',
  '{ namespace: "builderConfiguration", key: "modelARMaterialEffect" }',
].join(',')}]`

export const giftCardBundleProductHandles = ['gift-cards', 'style-edit-gift-card']

// write function to calculate discount percentage on base of compare_at_price and price
export const calculateDiscountPercentage = (price?: number, compareAtPrice?: number) => {
  if (!compareAtPrice || !price) return 0
  if (price === compareAtPrice || price > compareAtPrice) return 0
  const discount = compareAtPrice - price
  const discountPercentage = Math.floor((discount / compareAtPrice) * 100)
  // round to nearest 10
  return Math.round(discountPercentage / 10) * 10
}
