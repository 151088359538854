import { getColourSiblings, getProduct } from '@commerce/api/operations'
import getGlobalStaticProps from '@lib/globalStaticProps'
import { findProductBlocksPrismicUids, getDocByUid, getSingleDocByType } from '@lib/prismic'
import { getMetafieldsValueByKey, getSiblingColourTagByParent, slugify } from '@lib/product'
import { ParsedUrlQuery } from 'querystring'
import * as R from 'ramda'

export const kidsProductTypes = [
  'Bundle_Kids',
  'Bundle_Sheets_Kids',
  'Fitted_Sheet_Kids',
  'Flat_Sheet_Kids',
  'Quilt_Cover_Kids',
  'Pillowcases_Kids',
  'Mattress_Protector_Kids',
].map(slugify)
export const babyProductTypes = [
  'Fitted_Sheet_Baby',
  'Bundle_Travel_Baby',
  'Bassinet_Fitted_Sheet_Baby',
  'Mattress_Protector_Baby',
].map(slugify)

interface ProductPropsParams extends ParsedUrlQuery {
  slug: string
}

interface PrismicPreviewData {
  ref?: string
}

interface GetProductPropsArgs {
  params: ProductPropsParams
  previewData?: PrismicPreviewData
  locale?: string
  preview?: boolean
  pathType?: string
}

export async function getProductProps({
  params,
  previewData = {},
  locale,
  preview,
  pathType = 'regular',
}: GetProductPropsArgs) {
  const { ref } = previewData
  const globalProps = await getGlobalStaticProps(ref, locale)

  let product = null
  let colourSwatches = null
  let colourSiblings = null
  let productInfoDocs: any[] = []
  let productFeaturesDoc = null
  let productBottomBlockDoc = null
  let promoTileDoc = null
  let sizeGuideModal = null
  let productDetails = null

  try {
    const results = await Promise.all([
      getProduct(locale, params!.slug, 'no-cache'),
      getSingleDocByType('colour_swatches', ref, locale),
      getSingleDocByType('size_guide_modal', ref, locale),
    ])
    product = results[0]?.product || null
    colourSwatches = results[1]?.data?.colours
    sizeGuideModal = results[2]?.data || null
    // Tag: rule : range-type (need range, type and colour tags)
    const siblingTag = getSiblingColourTagByParent(product?.tags)
    if (siblingTag) colourSiblings = await getColourSiblings(siblingTag, locale)
  } catch (err) {
    console.log(err)
  }

  const productInfoUids = findProductBlocksPrismicUids(product?.tags || [], 'prismic:pib')
  const productFeaturesUids = findProductBlocksPrismicUids(product?.tags || [], 'prismic:pfb')
  const productBottomBlockUids = findProductBlocksPrismicUids(product?.tags || [], 'prismic:pbb')
  const productPromoTileUids = findProductBlocksPrismicUids(product?.tags || [], 'prismic:ppt')
  try {
    productFeaturesDoc = await getDocByUid('product_features_blocks', productFeaturesUids?.[0], ref, locale)
    productBottomBlockDoc = await getDocByUid('product_bottom_blocks', productBottomBlockUids?.[0], ref, locale)
    // productDetails = await getDocByUid('product_detail_page', productDetailsUids?.[0], ref, locale)
    productInfoDocs = await Promise.all(
      productInfoUids.map((uid) => getDocByUid('product_info_blocks', uid, ref, locale))
    )
    promoTileDoc = await getDocByUid('product_promo_tile', productPromoTileUids?.[0], ref, locale)
  } catch (err) {
    console.log(err)
  }
  productInfoDocs = productInfoDocs?.filter((p) => p)
  // get data of related components
  const bundle = getMetafieldsValueByKey(product?.metafields, 'bundle_components', true)
  const ctl = getMetafieldsValueByKey(product?.metafields, 'complete_the_look_components', true)
  const ppt = getMetafieldsValueByKey(product?.metafields, 'promo_tile', true)
  const productAvailability = getMetafieldsValueByKey(product?.metafields, 'available_countries', true)
  const componentHandles: any[] = R.concat(R.pluck('handle')(ctl), R.pluck('handle')(bundle))
  const componentProducts: any[] = R.pluck('product')(
    await Promise.all(componentHandles.filter((h) => h).map((handle) => getProduct(locale, handle, 'no-cache')))
  ).filter((p) => p)

  if (!product) {
    return { notFound: true }
  }

  const isKidsProduct = kidsProductTypes.includes(slugify(product?.productType))
  const isBabyProduct = babyProductTypes.includes(slugify(product?.productType))

  if (pathType === 'regular' && (isKidsProduct || isBabyProduct)) return { notFound: true }

  if (pathType === 'kids' && !isKidsProduct) return { notFound: true }

  if (pathType === 'baby' && !isBabyProduct) return { notFound: true }

  const formatedSiblingColours = colourSiblings?.products?.map((c: any) => {
    return {
      path: c?.path,
      colourway: c?.colourway,
      productType: c?.productType,
    }
  })

  return {
    props: {
      ...globalProps.props,
      productAvailability,
      product,
      colourSwatches,
      colourSiblings: formatedSiblingColours || [],
      productFeaturesDoc: productFeaturesDoc || null,
      productBottomBlockDoc: productBottomBlockDoc || null,
      productInfoDocs: productInfoDocs || null,
      promoTileDoc: promoTileDoc || null,
      promoTileConfig: ppt || null,
      componentProducts,
      preview: preview || false,
      prismicRef: ref || null,
      sizeGuideModal,
      productDetails,
    },
    revalidate: 172800, //48 hours: trying to reduce daily api calls,
  }
}

export const getProductPath = (url: string, productType?: string) => {
  const slugifiedProductType = productType ? slugify(productType) : ''
  if (kidsProductTypes.includes(slugifiedProductType)) {
    return `/kids/product/${url}`
  } else if (babyProductTypes.includes(slugifiedProductType)) {
    return `/baby/product/${url}`
  } else {
    return `/product/${url}`
  }
}
