import { useMutation } from '@apollo/client'
import { AttributeInput } from '@commerce/schema'
import type { Cart } from '@commerce/types/cart'
import { cartLinesRemoveVars } from '@commerce/utils'
import cartLinesAddMutation, { cartAddMultipleLinesVars } from '@commerce/utils/mutations/cart-lines-add'
import cartLinesRemoveMutation from '@commerce/utils/mutations/cart-lines-remove'
import { useUI } from '@components/ui'
import { useRouter } from 'next/router'
import { FC, useEffect } from 'react'

interface props {
  cart: Cart
  setMyCartLoaded: (value: boolean) => void
}

const emptyCart: (cart: Cart, locale: string | undefined, removeItems: any) => Promise<any> = (
  cart,
  locale,
  removeItems
) => {
  const items =
    cart?.lineItems.map((item) => {
      return item.id
    }) || []
  if (items.length > 0) {
    return removeItems({
      variables: cartLinesRemoveVars({ locale, itemIds: items }),
      context: { locale },
    })
  }
  return Promise.resolve()
}

export const LoadSharedMyCartFromURL: FC<props> = ({ cart, setMyCartLoaded }) => {
  const { openSidebar } = useUI()
  const { locale } = useRouter()
  const [removeItems] = useMutation(cartLinesRemoveMutation, {
    onCompleted() {
      openSidebar()
    },
    onError(error) {
      console.error(error)
    },
  })

  const [addItem] = useMutation(cartLinesAddMutation, {
    onCompleted() {
      openSidebar()
      history.replaceState({}, document.title, '/')
    },
    onError(error) {
      console.error('error adding item', error)
    },
  })

  useEffect(() => {
    const url = new URL(window.location.href)
    const params = new URLSearchParams(decodeURI(url.search))
    const productsInURL = params.getAll('smc')

    if (productsInURL.length > 0 && cart) {
      emptyCart(cart, locale, removeItems).then(() => {
        const products = productsInURL.map((productData) => {
          const parts = productData.split('|')
          const propertiesString = parts[2]
          let attributes: Array<AttributeInput> = []
          if (propertiesString) {
            propertiesString.split('||').map((propertyData) => {
              const propertyParts = propertyData.split(':')
              attributes.push({
                key: propertyParts[0],
                value: propertyParts[1] || '',
              })
            })
          }
          return {
            merchandiseId: btoa(`gid://shopify/ProductVariant/${Number(parts[0])}`),
            quantity: Number(parts[1]),
            attributes,
          }
        })
        products.reverse()

        addItem({
          variables: cartAddMultipleLinesVars(products, locale),
          context: { locale },
        })
      })
      setMyCartLoaded(true)
    }
  }, [cart, locale, addItem, removeItems, setMyCartLoaded])
  return <></>
}
