import { Cart } from '@commerce/types/cart'
import { Product } from '@commerce/types/product'
import { cartAddMultipleLinesVars } from '@commerce/utils/mutations/cart-lines-add'
import { cartLinesRemoveVars } from '@commerce/utils/mutations/cart-lines-remove'
import { addToCartAddonEvent } from '@lib/events/addToCart'
import { Addon } from '@lib/types/MiniCart'
import { useRouter } from 'next/router'
import { FC, useState } from 'react'
import { useAddons } from './AddonHooks'
import AddonItem, { SelectedVariantProps } from './AddonItem'
import { addonProperty, getAddonCartLineItem } from './AddonUtils'
interface AddonsListProps {
  addons: Addon[]
  cart: Cart
  addonInventoryLocations?: string | undefined
}

const AddonsList: FC<AddonsListProps> = ({ cart, addons, addonInventoryLocations }) => {
  const [loading, setLoading] = useState<Addon | null>(null)
  const { locale } = useRouter()
  const { addItem, removeItem, addonsToShow, saveAddonsToShow } = useAddons(
    locale || null,
    addons,
    cart,
    addonInventoryLocations
  )
  // Manage add to cart
  const manuallyUpdated = (addon: Addon) => {
    const sessionStorage = window.sessionStorage.getItem(addonProperty)
    const sessionStorageData = JSON.parse(sessionStorage || '{}')
    sessionStorageData[`${addon.index}_${addon.order_tracking_code}`] = '1'
    window.sessionStorage.setItem(addonProperty, JSON.stringify(sessionStorageData))
  }
  const addToCart = (variant: SelectedVariantProps, addon: Addon, product: Product) => {
    const products = [
      {
        merchandiseId: variant?.variant.id,
        quantity: variant.quantity,
        attributes: [
          {
            key: addonProperty,
            value: addon.order_tracking_code || 'Addon',
          },
        ],
      },
    ]
    manuallyUpdated(addon)
    setLoading(addon)
    addItem({
      variables: cartAddMultipleLinesVars(products, locale),
      context: { locale },
    }).then(() => {
      setLoading(null)
    })
    addToCartAddonEvent(products, product, locale)
  }

  const removeFromCart = (addon: Addon) => {
    const lineItem = getAddonCartLineItem(addon, cart)
    if (lineItem) {
      setLoading(addon)
      manuallyUpdated(addon)
      removeItem({
        variables: cartLinesRemoveVars({ locale, itemIds: [lineItem.id] }),
        context: { locale },
      }).then(() => {
        setLoading(null)
      })
    }
  }
  const dismissAddon = (addon: Addon) => {
    if (addonsToShow) {
      window.sessionStorage.setItem(
        `addon_${btoa(`${locale}_${addon.index}_${addon.order_tracking_code}`)}_dismiss`,
        'true'
      )
      const addonToBeDismissed = addonsToShow.find((addonToShow) => addonToShow.index === addon.index)
      const otherAddons = addonsToShow.filter((addonToShow) => addonToShow.index !== addon.index)
      if (addonToBeDismissed) {
        addonToBeDismissed.dismissed = true
        saveAddonsToShow([...otherAddons, addonToBeDismissed])
      }
    }
  }

  return (
    <ul>
      {addonsToShow &&
        addonsToShow
          .filter((addon) => addon.enabled && !addon.dismissed)
          .map((addon, index) => {
            const foundAddonItems = cart?.lineItems.find((lineItem) => {
              return lineItem?.customAttributes?.find(
                (attribute) => attribute.key === addonProperty && attribute.value === addon.order_tracking_code
              )
            })
            if (!foundAddonItems)
              return (
                <AddonItem
                  key={`addon-${index}-${locale}`}
                  addToCart={addToCart}
                  removeFromCart={removeFromCart}
                  dismissAddon={dismissAddon}
                  loading={loading === addon ? true : false}
                  cart={cart}
                  data={addon}
                />
              )
          })}
    </ul>
  )
}

export default AddonsList
