import { ApolloCache, DefaultContext, MutationFunctionOptions, OperationVariables, useMutation } from '@apollo/client'
import { getColourSiblingsForAddons } from '@commerce/api/operations'
import { Cart, LineItem } from '@commerce/types/cart'
import cartLinesAddMutation from '@commerce/utils/mutations/cart-lines-add'
import cartLinesRemoveMutation from '@commerce/utils/mutations/cart-lines-remove'
import { getSiblingColourTagByParent } from '@lib/product'
import { Addon } from '@lib/types/MiniCart'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import {
  addonProperty,
  getAddonCartLineItem,
  getAddonProduct,
  getAddonSimilarProductAlreadyInCart,
  isAddonDismissed,
} from './AddonUtils'
interface AddonError {
  error: string
  color: string
}
export const useAddons: (
  locale: string | null,
  addons: Addon[] | null,
  cart: Cart | null,
  addonInventoryLocations?: string | null
) => {
  addItem: (
    options?: MutationFunctionOptions<any, OperationVariables, DefaultContext, ApolloCache<any>> | undefined
  ) => Promise<any>
  removeItem: (
    options?: MutationFunctionOptions<any, OperationVariables, DefaultContext, ApolloCache<any>> | undefined
  ) => Promise<any>
  addonsToShow: Addon[] | null
  loadingAddon: Addon | null
  setLoadingAddon: (addon: Addon) => void
  saveAddonsToShow: (addon: Addon[]) => void
  getIncompatibleError: (lineItem: LineItem) => AddonError[] | null
  hasIncompatibleItems: (addon: Addon) => boolean
} = (locale, addons, cart, addonInventoryLocations) => {
  const [addonsToShow, setAddonsToShow] = useState<Addon[] | null>(null)
  const [loadingAddon, setLoadingAddonState] = useState<Addon | null>(null)
  const [addItem] = useMutation(cartLinesAddMutation, {
    onCompleted() {
      setLoadingAddonState(null)
    },
    onError() {
      setLoadingAddonState(null)
    },
  })

  const [removeItem] = useMutation(cartLinesRemoveMutation, {
    onCompleted() {
      setLoadingAddonState(null)
    },
    onError() {
      setLoadingAddonState(null)
    },
  })

  const fetchAddonProductInfo = async (addon: Addon, locale: string, addonsInventoryLocations: string) => {
    const { product_url_path } = addon
    if (product_url_path) {
      const productParts = product_url_path.split('/')
      const slug = productParts[productParts.length - 1]
      const addon_product = await getAddonProduct(slug, locale, addonsInventoryLocations)
      let colourSiblings = null
      const siblingTag = getSiblingColourTagByParent(addon_product?.tags)
      if (siblingTag) colourSiblings = await getColourSiblingsForAddons(siblingTag, locale)
      // Note: removed the logic of addonInventoryLocation
      // console.log('colour sibling', colourSiblings)
      // const addon_products = colourSiblings?.products?.map(async (c: any) => {
      //   return getAddonProduct(c.slug, locale, addonsInventoryLocations)
      // })

      return {
        products: colourSiblings?.products || null,
      }
    }
  }

  const filterAddonsToShow = (updatedAddons: Addon[], cart: Cart, addonProperty: string) => {
    return updatedAddons.filter((addon) => {
      const showTags = JSON.parse(addon.show_with_product_tags || '[]')
      const hideTags = JSON.parse(addon.hide_with_product_tags || '[]')
      const nonAddonItems = cart.lineItems.filter((lineItem) => {
        return !lineItem.customAttributes?.some((attribute) => attribute.key === addonProperty)
      })
      if (nonAddonItems.length > 0) {
        const similarProductAlreadyInCart = getAddonSimilarProductAlreadyInCart(addon, cart)
        const cartCompatibleLineItems = nonAddonItems.some((item) => item.tags.some((tag) => showTags.includes(tag)))
        const cartIncompatibleLineItems = nonAddonItems.some((item) => item.tags.some((tag) => hideTags.includes(tag)))
        const isDismissed = isAddonDismissed(addon, locale)
        if (isDismissed) {
          addon.dismissed = true
        }
        return (
          !similarProductAlreadyInCart &&
          cartCompatibleLineItems &&
          !cartIncompatibleLineItems &&
          (addon.products || addon.product_url_path)
        )
      }
      return false
    })
  }

  const updateAddons = async (
    locale: string,
    addons: Addon[],
    addonInventoryLocations: string,
    cart: Cart,
    addonProperty: string,
    setAddonsToShow: Dispatch<SetStateAction<Addon[] | null>>
  ) => {
    let updatedAddons = addons || []

    if (updatedAddons.length > 0) {
      const promises = updatedAddons.map(async (addon, index) => {
        const addonProductInfo: any = await fetchAddonProductInfo(addon, locale, addonInventoryLocations)
        updatedAddons[index] = {
          ...addon,
          index,
          ...addonProductInfo,
        }
      })
      await Promise.all(promises)
      setAddonsToShow(filterAddonsToShow(updatedAddons, cart, addonProperty).slice(0, 2))
    }
  }

  useEffect(() => {
    if (locale && addons && cart) {
      updateAddons(locale, addons, addonInventoryLocations!, cart, addonProperty, setAddonsToShow)
    }
  }, [locale, addons, addonInventoryLocations, cart])

  const hasIncompatibleItems: (addon: Addon) => boolean = (addon) => {
    const found = cart!.lineItems.find((lineItem) => {
      return lineItem.tags.some((tag) => addon.incompatible_product_tags?.includes(tag))
    })
    return found ? true : false
  }

  const getIncompatibleError: (lineItem: LineItem) => AddonError[] | null = (lineItem) => {
    const incompatibleAddons = addonsToShow?.filter((addon) => {
      if (cart) {
        const itemIsIncompatible = (lineItem.tags as string[]).some(
          (tag) => addon.incompatible_product_tags?.includes(tag) && getAddonCartLineItem(addon, cart)
        )
        return itemIsIncompatible
      }
      return false
    })
    if (incompatibleAddons !== undefined) {
      let addonErrors: AddonError[] = []
      incompatibleAddons.map((incompatibleAddon) => {
        addonErrors.push({
          error: incompatibleAddon.incompatible_line_item_error_message!,
          color: incompatibleAddon.incompatible_line_item_colour!,
        })
      })
      return addonErrors
    }
    return null
  }

  const saveAddonsToShow = (addons: Addon[]) => {
    setAddonsToShow(addons)
  }

  const setLoadingAddon = (addon: Addon) => {
    setLoadingAddonState(addon)
  }

  return {
    addItem,
    removeItem,
    loadingAddon,
    setLoadingAddon,
    addonsToShow,
    saveAddonsToShow,
    getIncompatibleError,
    hasIncompatibleItems,
  }
}
