export function getResizedImageUrl(url: string, size: string): string {
  if (!url) return '';
  try {
    const [baseUrl, queryParams] = url.split('?');
    const sizeRegex = /_(\d+)x/;
    const hasSizeMatch = baseUrl.match(sizeRegex);
    let newBaseUrl;
    if (hasSizeMatch) {
      const currentSize = `${hasSizeMatch[1]}x`;
      if (currentSize === size) {
        return url;
      } else {
        newBaseUrl = baseUrl.replace(sizeRegex, `_${size}`);
      }
    } else {
      const lastDotIndex = baseUrl.lastIndexOf('.');
      if (lastDotIndex === -1) return url;
      const extension = baseUrl.slice(lastDotIndex);
      const urlWithoutExtension = baseUrl.slice(0, lastDotIndex);
      newBaseUrl = `${urlWithoutExtension}_${size}${extension}`;
    }
    if (queryParams) {
      return `${newBaseUrl}?${queryParams}`;
    }
    return newBaseUrl;
  } catch (error) {
    console.error('Error resizing image URL:', error);
    return url;
  }
}
export const elevarImagePlaceHolderUrl = 'https://sheetsociety.com/product-img-placeholder.svg'