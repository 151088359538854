import { gql } from '@apollo/client'
import { getCartId } from '..'
import { cartDetailsFragment } from '../fragements/cart'

const cartLinesUpdateMutation = /* GraphQL */ gql`
  mutation cartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
    cartLinesUpdate(cartId: $cartId, lines: $lines) {
      cart {
        ...cartDetails
      }
      userErrors {
        code
        field
        message
      }
    }
  }
  ${cartDetailsFragment}
`

type MutationVars = {
  locale: string | undefined
  id: string
  quantity: number
}

export const cartLinesUpdateVars = ({ locale, id, quantity }: MutationVars) => ({
  cartId: getCartId(locale),
  lines: [
    {
      id,
      quantity,
    },
  ],
})
export default cartLinesUpdateMutation
