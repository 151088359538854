import { gql } from '@apollo/client'
import { AttributeInput } from '@commerce/schema'
import { CartLine } from '@commerce/types/cart'
import { getCartId, getCurrencyCountryCode } from '..'
import { cartDetailsFragment } from '../fragements/cart'



const cartLinesAddMutation = /* GraphQL */ gql`
  mutation cartLinesAdd($cartId: ID!, $lines: [CartLineInput!]!, $country: CountryCode!)
  @inContext(country: $country) {
    cartLinesAdd(cartId: $cartId, lines: $lines) {
      cart {
        ...cartDetails
      }
      userErrors {
        code
        field
        message
      }
    }
  }
  ${cartDetailsFragment}
`

type MutationVars = {
  locale: string | undefined
  variantId: string
  quantity: number
  attributes?: Array<AttributeInput>
}

export const cartLinesAddVars = ({ locale, variantId, quantity, attributes }: MutationVars) => ({
  cartId: getCartId(locale),
  lines: [
    {
      merchandiseId: variantId,
      quantity,
      attributes,
    },
  ],
  country: getCurrencyCountryCode(locale),
})

export const cartAddMultipleLinesVars = (lines: CartLine[], locale: string | undefined) => ({
  cartId: getCartId(locale),
  lines,
  country: getCurrencyCountryCode(locale),
})

export default cartLinesAddMutation
