import { useQuery } from '@apollo/client'
import commerceConfig from '@commerce/config'
import { LineItem } from '@commerce/types/cart'
import { getCartId, getCartQuery, getCurrencyCountryCode, normalizeCart } from '@commerce/utils'
import { AccountV2, CartV2, Location, Search } from '@components/icons'
import { useUI } from '@components/ui'
import processLocale, { LOCALES_MAP } from '@lib/locale'
import { AccountAction, ChatAction } from '@lib/types/Navigation'
import cn from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { RichTextBlock } from 'prismic-reactjs'
import { FC } from 'react'
interface Props {
  handleShowSearchBar: () => void
  handleActiveTitle: (nav_title: string) => void
  navigationCondensed: boolean
  activeMegaTitle: string
  accountAction: AccountAction
  chatAction: ChatAction
  navElementColour: string
}
const countItem = (count: number, item: LineItem) => count + item.quantity
const UserNavigation: FC<Props> = ({
  handleShowSearchBar,
  handleActiveTitle,
  navigationCondensed,
  accountAction,
  chatAction,
  activeMegaTitle,
  navElementColour,
}) => {
  const {
    config: { defaultAlt },
  } = commerceConfig
  const { locale } = useRouter()
  const cartId = getCartId(locale)
  const { data } = useQuery(getCartQuery, {
    variables: {
      cartId,
      country: getCurrencyCountryCode(locale),
      language: 'EN',
    },
    context: { locale },
    skip: !cartId,
  })

  const cart = data?.cart ? normalizeCart(data.cart) : null
  const itemsCount = cart?.lineItems.reduce(countItem, 0) ?? 0
  const { openSidebar, setModalView, openModal } = useUI()
  const rootVariants = {
    open: {
      display: 'block',
      opacity: 1,
    },
    closed: {
      display: 'none',
      opacity: 1,
      trasition: {
        duration: 0.3,
      },
    },
  }
  const isEmptyText = (text: RichTextBlock[] | null) => {
    const richTextContent = text && text[0]
    if (!richTextContent) return false
    if (
      typeof richTextContent !== 'object' ||
      richTextContent.type !== 'paragraph' ||
      richTextContent.text !== '' ||
      !Array.isArray(richTextContent.spans)
    ) {
      return false
    }

    return true
  }
  const {
    account_action_display_as_dropdown,
    account_action_title,
    account_action_link,
    account_action_image,
    account_action_login_text,
    account_action_login_link,
    account_action_signup_text,
    account_action_signup_link,
  } = accountAction || {}
  const { chat_action_display_as_dropdown, chat_action_title, chat_action_image, chat_action_text } = chatAction || {}

  const currentLocale = processLocale(locale)
  const showCountrySelector = () => {
    setModalView('COUNTRY_SELECTOR_VIEW')
    openModal()
  }

  return (
    <div className="col-span-1 flex items-end justify-end">
      <ul className="flex items-center h-full gap-16 useNav">
        <li
          className={cn('relative h-full flex justify-center items-end pb-16 font-g-bold', {
            active: activeMegaTitle === 'CountrySelector',
          })}
        >
          <button
            className="flex items-center justify-center gap-2 leading-16 font-bold tracking-spacingBitWider"
            aria-label={`Language selector ${LOCALES_MAP[currentLocale.toLowerCase()]?.countrySelectorCode}`}
            style={{ color: navElementColour }}
            onClick={showCountrySelector}
            onMouseOver={() => handleActiveTitle('CountrySelector')}
            onMouseOut={() => handleActiveTitle('')}
          >
            <span className="cursor-pointer">
              <Location width={16} height={16} color={navElementColour} />
            </span>
            {LOCALES_MAP[currentLocale.toLowerCase()]?.countrySelectorCode}
          </button>
        </li>
        <li
          className={cn(`relative h-full flex justify-center items-end pb-14`, {
            active: activeMegaTitle === 'Search',
          })}
        >
          <button
            aria-label="Search"
            onClick={handleShowSearchBar}
            onMouseOver={() => handleActiveTitle('Search')}
            onMouseLeave={() => handleActiveTitle('')}
          >
            <Search color={navElementColour} />
          </button>
        </li>

        {/* <li
          onClick={handleChat}
          onMouseOver={() => handleActiveTitle('Chat')}
          onMouseLeave={() => handleActiveTitle('')}
          className={cn(`relative h-full flex justify-center items-end pb-16`, {
            active: activeMegaTitle === 'Chat',
          })}
        >
          <button>
            <ChatV2 color={navElementColour} />
          </button>
          <AnimatePresence>
            {activeMegaTitle === 'Chat' && chat_action_display_as_dropdown && (
              <motion.div
                variants={rootVariants}
                initial={'closed'}
                animate={activeMegaTitle === 'Chat' ? 'open' : 'closed'}
                exit={'closed'}
                className={cn(
                  `w-200 min-h-204 absolute z-10 pt-24 px-20 pb-20 bg-white rounded-b-16 top-36 shadow-magical, ${
                    navigationCondensed ? 'top-60' : 'top-78'
                  }`
                )}
              >
                <div className="w-160 h-160 rounded-16 relative overflow-hidden">
                  {chat_action_image && chat_action_image.url && (
                    <Image
                      src={chat_action_image.url}
                      fill
                      alt={chat_action_image.alt || defaultAlt}
                      placeholder="blur"
                      sizes="100vw, 100vh"
                      blurDataURL={`${chat_action_image.url}&blur=500`}
                    />
                  )}
                  {chat_action_title && (
                    <div className="absolute text-white z-10 top-10 w-2/3 left-12 font-g-regular text-16 leading-18 font-normal tracking-spacingBitTigher">
                      {chat_action_title}
                    </div>
                  )}
                </div>
                {chat_action_text && !isEmptyText(chat_action_text) && (
                  <div className=" text-almostBlack font-g-regular text-12 leading-20 font-normal mt-12">
                    <WYSIWYG content={chat_action_text} />
                  </div>
                )}
              </motion.div>
            )}
          </AnimatePresence>
        </li> */}
        <li
          className={cn(`relative h-full flex justify-center items-end pb-16`, {
            active: activeMegaTitle === 'Account',
          })}
          onMouseOver={() => handleActiveTitle('Account')}
          onMouseLeave={() => handleActiveTitle('')}
        >
          <Link aria-label="Account" href={account_action_link || '/me'}>
            <AccountV2 color={navElementColour} />
          </Link>
          <AnimatePresence>
            {activeMegaTitle === 'Account' && account_action_display_as_dropdown && (
              <motion.div
                variants={rootVariants}
                initial={'closed'}
                animate={activeMegaTitle === 'Account' ? 'open' : 'closed'}
                exit={'closed'}
                className={cn(
                  `w-200 min-h-204 absolute z-10 pt-24 px-20 pb-20 bg-white rounded-b-16 top-36 shadow-magical ${
                    navigationCondensed ? 'top-60' : 'top-78'
                  }`
                )}
              >
                <div className="w-160 h-160 rounded-16 relative overflow-hidden">
                  {account_action_image && account_action_image.url && (
                    <Image
                      src={account_action_image.url}
                      fill
                      alt={account_action_image.alt || defaultAlt}
                      placeholder="blur"
                      sizes="100vw, 100vh"
                      blurDataURL={`${account_action_image.url}&blur=500`}
                    />
                  )}
                  {account_action_title && (
                    <div className="absolute text-white z-10 top-10 w-2/3 left-12 font-g-regular text-16 leading-18 font-normal tracking-spacingBitTigher">
                      {account_action_title}
                    </div>
                  )}
                </div>
                {((account_action_login_text && account_action_login_link) ||
                  (account_action_signup_link && account_action_signup_text)) && (
                  <div className="flex items-center justify-evenly mt-16 text-black font-g-regular font-bold text-12 leading-16 tracking-spacingBitWider">
                    <Link href={account_action_login_link || '/me'}>
                      <div className=" hover:text-hoverGrey">{account_action_login_text}</div>
                    </Link>
                    <div className=" text-black">|</div>
                    <Link href={account_action_signup_link || '/me/signup'}>
                      <div className=" hover:text-hoverGrey">{account_action_signup_text}</div>
                    </Link>
                  </div>
                )}
              </motion.div>
            )}
          </AnimatePresence>
        </li>
        <li
          className={cn(`relative h-full flex justify-center items-end pb-16`, {
            active: activeMegaTitle === 'Cart',
          })}
        >
          <button
            aria-label="Cart"
            onClick={openSidebar}
            onMouseOver={() => handleActiveTitle('Cart')}
            onMouseLeave={() => handleActiveTitle('')}
            className="relative"
          >
            <CartV2 color={navElementColour} />
            {itemsCount > 0 && itemsCount < 10 && (
              <div className="w-20 h-20 absolute rounded-full itemNumber text-10 font-bold text-white flex items-center justify-center">
                {itemsCount}
              </div>
            )}
            {itemsCount > 0 && itemsCount >= 10 && (
              <div className="w-20 h-20 absolute rounded-full itemNumber text-10 font-bold text-white flex items-center justify-center">
                <div className="w-3 h-3 bg-white mx-auto rounded-full"></div>
              </div>
            )}
          </button>
        </li>
      </ul>
      <style jsx>
        {`
          .itemNumber {
            background: #be4d35;
            top: -12px;
            right: -12px;
          }
          .useNav li.active:after {
            z-index: 15;
            position: absolute;
            bottom: 0px;
            content: '';
            height: 2px;
            width: 100%;
            background-color: #6c704a;
            display: block;
          }
        `}
      </style>
    </div>
  )
}

export default UserNavigation
