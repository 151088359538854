import { gql } from '@apollo/client'
import { getCartId } from '..'
import { cartDetailsFragment } from '../fragements/cart'


const cartLinesRemoveMutation = /* GraphQL */ gql`
  mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
    cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
      cart {
        ...cartDetails
      }
      userErrors {
        code
        field
        message
      }
    }
  }
  ${cartDetailsFragment}
`

type MutationMultipleVars = {
  locale: string | undefined
  itemIds: string[]
}

export const cartLinesRemoveVars = ({ locale, itemIds }: MutationMultipleVars) => ({
  cartId: getCartId(locale),
  lineIds: itemIds,
})

export default cartLinesRemoveMutation
