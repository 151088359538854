/* eslint-disable @next/next/no-sync-scripts */
import { useMutation, useQuery } from '@apollo/client'
import { customerAccessTokenVar } from '@commerce/client'
import commerceConfig from '@commerce/config'
import { getCurrencyCountryCode, getCustomerToken, normalizeCart, setCheckoutUrl } from '@commerce/utils'
import getCartId from '@commerce/utils/get-cart-id'
import cartCreateMutation from '@commerce/utils/mutations/cart-create'
import getCartQuery from '@commerce/utils/queries/get-cart-query'
import setCartId from '@commerce/utils/set-cart-id'
import { CartSidebarView } from '@components/cart'
import minicartstyle from '@components/cart/CartSidebarView/CartSidebarView.module.css'
import LoopReturnsModal from '@components/cart/LoopReturns/LoopReturnsModal'
import { FeatureBar, TranslatingAnnouncementBar } from '@components/common'
import { Button, LoadingDots, Modal } from '@components/ui'
import SlideInSidebar from '@components/ui/SlideInSidebar'
import { useUI } from '@components/ui/context'
import { default as seoConfig } from '@config/seo.json'
import NewsletterPopup from '@containers/NewsletterPopup'
import { ElevarEvents } from '@lib/events/elevarEvents'
import { getDataLayerProductIdByIds, getNumberPartFromId } from '@lib/events/general'
import { fetcher, swrOptions } from '@lib/helpers/swr'
import useUserProperties, { ELEVAR_USER_DATA_SESSION_NAME } from '@lib/hooks/useUserProperties'
import { EcommerceData, Product } from '@lib/types/Elevar'
import { Addon, MiniCart } from '@lib/types/MiniCart'
import {
  Footer,
  FooterQuickLinks,
  MainNavigationData,
  ScrollingAnnouncementBar,
  ServiceUSP,
  SocialAndPayments,
} from '@lib/types/Navigation'
import cn from 'classnames'
import isbot from 'isbot'
import dynamic from 'next/dynamic'
import NextHead from 'next/head'
import { useRouter } from 'next/router'
import { RichText, RichTextBlock } from 'prismic-reactjs'
import { FC, ReactNode, useEffect, useMemo, useState } from 'react'
import useSWR from 'swr'
import BackgroundImage from '../BackgroundImage'
import CountrySuggestion from '../CountrySuggestion'
import GDPR from '../GDPR'
import LiveChatButton from '../LiveChatButton'
import MobileNavV2 from '../MobileNavV2'
import NavigationV2 from '../NavigationV2'
import s from './Layout.module.css'
import { elevarImagePlaceHolderUrl } from '@lib/image'

const Loading = () => (
  <div className="w-80 h-80 flex items-center text-center justify-center p-3">
    <LoadingDots />
  </div>
)

const dynamicProps = {
  loading: Loading,
}
const DynamicServiceUSP = dynamic(() => import('@components/common/ServiceUSP'))
const DynamicFooter = dynamic(() => import('@components/common/Footer'))
const CountrySelectorView = dynamic(() => import('@components/common/CountrySelector'), { ...dynamicProps })

const ModalView: FC<{ modalView: string; closeModal(): any }> = ({ modalView, closeModal }) => {
  return (
    <Modal onClose={closeModal}>
      {modalView === 'COUNTRY_SELECTOR_VIEW' && <CountrySelectorView onClose={closeModal} />}
    </Modal>
  )
}

const ModalUI: FC = () => {
  const { displayModal, modalView, closeModal } = useUI()
  return displayModal ? <ModalView modalView={modalView} closeModal={closeModal} /> : null
}

interface LayoutProps {
  children?: ReactNode
  pageProps: {
    doc: any
    preview?: boolean
    scrollingAnnouncementBar: ScrollingAnnouncementBar
    pages: any[]
    navigationData?: MainNavigationData
    miniCart?: MiniCart
    footer?: Footer
    serviceUSP?: ServiceUSP
    footerQuickLinks?: FooterQuickLinks
    socialAndPayments?: SocialAndPayments
    globalSettings?: {
      enable_newsletter_popup: boolean
      klaviyo_list_id: string
      google_optimize_container_id: string
      google_optimize_async: boolean
      google_optimize_anti_flicker: boolean
      enable_live_chat: boolean
      body: {
        items: {
          [key: string]: any
        }[]
        slice_type: string
      }[]
      body1: {
        items: {
          [key: string]: any
        }[]
        slice_type: string
      }[]
      newsletter_popup_heading?: string
      newsletter_popup_supporting_text?: RichTextBlock[]
      newsletter_popup_invalid_email_message?: string
      newsletter_popup_general_error_message?: string
      newsletter_popup_button_text_mobile?: string
      newsletter_popup_button_text_desktop?: string
      newsletter_popup_close?: string
      newsletter_popup_successful_heading?: string
      newsletter_popup_successful_supporting_text?: RichTextBlock[]
      newsletter_popup_successful_cta_text?: string
      newsletter_popup_timeout: number
      collect_mobile_number: boolean
      tag_automation_background_colour?: string
      tag_automation_text_colour?: string
    }
    backgroundSettings: any
    uid: string
    prismicRef: any
    locale: string
    builderFullScreen: boolean
    productAvailability: string[]
  }
}

interface SidebarViewProps {
  miniCart?: MiniCart | null
  prismicRef: any
}

const SidebarView: FC<SidebarViewProps> = ({ miniCart, prismicRef }) => {
  const { closeSidebar, displaySidebar } = useUI()
  const className = cn(minicartstyle.miniCartSidebar, {
    'translate-x-0': displaySidebar,
    'translate-x-full': !displaySidebar,
  })
  return (
    <SlideInSidebar onClose={closeSidebar} display={displaySidebar} orientation="right" className={className}>
      {miniCart && (
        <CartSidebarView
          isOpened={displaySidebar}
          miniCart={miniCart}
          spendAndSave={{
            enabled: miniCart?.spend_and_save_enabled || false,
            message: miniCart?.spend_and_save_message || '',
            excludeProductTypes: miniCart?.spend_and_save_exclude_prod_types || '',
            tiers: miniCart?.spend_and_save_tiers || '',
            subMessage: miniCart?.spend_and_save_sub_message || '',
          }}
          shareMyCart={{
            title:
              miniCart?.share_title && miniCart?.share_title?.length > 0 ? RichText.asText(miniCart.share_title) : '',
            body: miniCart?.share_utm_campaign || '',
            utmSource: miniCart?.share_utm_source || 'website',
            utmMedium: miniCart?.share_utm_medium || 'cart',
            utmCampaign: miniCart?.share_utm_campaign || 'shareMyCart',
          }}
          prismicRef={prismicRef}
        />
      )}
    </SlideInSidebar>
  )
}

const Layout: FC<LayoutProps> = ({ children, pageProps: { ...pageProps } }) => {
  const { backgroundSettings, uid, prismicRef, builderFullScreen, globalSettings, productAvailability = [] } = pageProps
  const { asPath, locale } = useRouter()
  const { openSidebar, setTagLibraryTags } = useUI()
  const cartId = getCartId(locale)
  const customerAccessToken = getCustomerToken(locale)
  const tagLibraryTags = globalSettings?.body1.find((index) => index.slice_type === 'tag')?.items

  let miniCart: MiniCart
  const { data: miniCartDoc } = useSWR(`/api/prismic/cart?locale=${locale}`, fetcher, swrOptions)

  if (miniCartDoc?.data?.body1) {
    const addons = (miniCartDoc.data as MiniCart).body1
      .filter((slice) => {
        return slice.slice_type === 'add-on-v2'
      })
      .map((slice) => slice.primary) as Addon[]
    miniCartDoc.data['addons'] = addons
  }
  miniCart = miniCartDoc?.data

  customerAccessTokenVar(customerAccessToken)
  const {
    config: { context, defaultLocale },
  } = commerceConfig
  const url = `${seoConfig.openGraph.url}`
  const path = asPath
  const gdprData = {
    buttonText: 'Got It',
  }

  useEffect(() => {
    setTagLibraryTags({
      tagLibraryTags: tagLibraryTags || [],
      tagAutomationSettings: {
        backgroundColour: globalSettings?.tag_automation_background_colour,
        textColour: globalSettings?.tag_automation_text_colour,
      },
    })
  }, [
    tagLibraryTags,
    setTagLibraryTags,
    globalSettings?.tag_automation_background_colour,
    globalSettings?.tag_automation_text_colour,
  ])

  useEffect(() => {
    if (locale) {
      // Save the impact irclickid code into localStorage to be used in the checkout link. Check the CartSidebarView component
      const urlParams = new URLSearchParams(window.location.search)
      const impactIrclickid = urlParams.get('irclickid')
      if (impactIrclickid) {
        window?.localStorage.setItem(`irclickid${locale}`, impactIrclickid)
      }
    }
  }, [locale])

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const cartParam = urlParams.get('cart')
    if (cartParam) {
      openSidebar()
    }
  }, [openSidebar])

  const [createCart] = useMutation(cartCreateMutation, {
    context: { locale },
    variables: {
      input: {
        buyerIdentity: {
          countryCode: getCurrencyCountryCode(locale),
        },
      },
      country: getCurrencyCountryCode(locale),
    },
    onCompleted(data) {
      const cart = data?.cartCreate?.cart
      const cartId = cart?.id
      const checkoutUrl = cart?.checkoutUrl
      if (cartId) {
        setCartId(locale, cartId)
        setCheckoutUrl(locale, checkoutUrl)
      }
    },
  })

  const { loading, error, data } = useQuery(getCartQuery, {
    variables: {
      cartId,
      country: getCurrencyCountryCode(locale),
      language: 'EN',
    },
    context: { locale },
    skip: !cartId,
  })

  useEffect(() => {
    //create new cart if no cart id
    //create new cart if there is error(edge case due to env switching)
    //create new cart if cart is null (cart is completed and deleted)
    if (!cartId || (!loading && error) || (cartId && !loading && data && !data?.cart)) {
      createCart()
    }
  }, [cartId, createCart, data, error, loading])

  const exitPreviewMode = () => (location.href = '/api/exit-preview')

  const siteURL = 'https://sheetsociety.com'
  const canonicalURL = `${siteURL}/${locale}${asPath === '/' ? '' : asPath}`.split('?')?.[0]
  const countryFlags = pageProps?.globalSettings?.body.find((index) => index.slice_type === 'countries')
  const [backgroundMatch, setBackgroundMatch] = useState(false)

  const [isBot, setIsBot] = useState<boolean>(true)
  useEffect(() => {
    if (typeof window !== undefined) {
      setIsBot(isbot(window.navigator.userAgent))
    }
  }, [])

  const alternateLinks = useMemo(() => {
    const links = []
    links.push(
      <link key={`alternates_x-default`} rel="alternate" hrefLang="x-default" href={`${url}${defaultLocale}${path}`} />
    )
    links.push(
      ...Object.values(context)
        .filter((c: any) => !productAvailability.length || productAvailability.includes(c.language))
        .map((c: any, key: number) => (
          <link
            key={`alternates_${key}`}
            rel="alternate"
            hrefLang={c.language}
            href={`${seoConfig.openGraph.url}${c.language}${path}`}
          />
        ))
    )
    return links
  }, [context, defaultLocale, path, productAvailability, url])

  //Elevar dl_user_data start
  const { userProperties, isLoadingCustomer } = useUserProperties()
  const cartData = data?.cart ? normalizeCart(data.cart) : null
  const cart_total = cartData?.totalPrice?.toString()
  const products: Product[] = cartData
    ? cartData.lineItems?.map((item, index) => {
        const id = getDataLayerProductIdByIds(item.productId, item.variant?.id, locale)
        return {
          id,
          name: item.name || '',
          brand: item.name?.split('-')?.[0]?.trim(),
          category: item.type || '',
          variant: item.variant?.name || '',
          price: item.variant?.price?.toString() || '',
          quantity: item.quantity?.toString() || '',
          position: index + 1,
          list: `/${locale}/collections/${item.name?.split('-')?.[0]?.trim().toLowerCase().split(' ').join('-')}` || '',
          product_id: getNumberPartFromId(item.productId),
          variant_id: getNumberPartFromId(item.variant?.id),
          compare_at_price:
            typeof item.variant?.listPrice === 'number' && !isNaN(item.variant?.listPrice)
              ? item.variant?.listPrice?.toString()
              : '0.0',
          image: item.variant?.image?.url || elevarImagePlaceHolderUrl,
        }
      })
    : []
  const ecommerceData: EcommerceData = {
    currencyCode: cartData?.currency?.code!,
    cart_contents: {
      products: products,
    },
  }
  useEffect(() => {
    if (!isLoadingCustomer && cartData) {
      ElevarEvents('dl_user_data', userProperties, ecommerceData, cart_total)
    }
  }, [cartData === null])

  useEffect(() => {
    if (window?.sessionStorage) {
      window.sessionStorage.setItem(ELEVAR_USER_DATA_SESSION_NAME, JSON.stringify({ ecommerceData, cart_total }))
    }
  }, [cartData])

  //Elevar dl_user_data end
  if (builderFullScreen) {
    return (
      <>
        <NextHead>
          <meta name="robots" content="noindex,nofollow" />
        </NextHead>
        <main className="overflow-hidden">{children}</main>
      </>
    )
  }

  return (
    <div className={cn(s.root)}>
      <NextHead>
        <link rel="canonical" href={canonicalURL} />
        {alternateLinks}
      </NextHead>
      {backgroundSettings?.desktop_background_image?.url && uid && (
        <BackgroundImage settings={backgroundSettings} uid={uid} setBackgroundMatch={setBackgroundMatch} />
      )}

      {/* {pageProps?.announcementBar && <AnnouncementBar bannerData={pageProps.announcementBar} />}
      {pageProps?.utilityBar && <UtilityBar utilityData={pageProps.utilityBar} transparentHeader={backgroundMatch} />}
      {pageProps?.navigationPages && (
        <Navigation navItems={pageProps.navigationPages} transparentHeader={backgroundMatch} />
      )}
      {pageProps?.navigationPages && (
        <MobileNav
          navItems={pageProps.navigationPages}
          bannerData={pageProps.announcementBar}
          transparentHeader={backgroundMatch}
        />
      )}
      {pageProps?.uspBar && <USPBar uspBar={pageProps.uspBar} transparentHeader={backgroundMatch} />} */}
      {/* <Navbar links={navBarlinks} /> */}
      {/* {pageProps?.oldAnnouncementBar && <AnnouncementBar bannerData={pageProps.oldAnnouncementBar} />} */}
      {pageProps?.scrollingAnnouncementBar && (
        <TranslatingAnnouncementBar bannerProps={pageProps?.scrollingAnnouncementBar} />
      )}
      {pageProps?.navigationData && <NavigationV2 navData={pageProps?.navigationData} />}
      {pageProps?.navigationData && (
        <MobileNavV2
          navData={pageProps?.navigationData}
          // oldBannerProps={pageProps.oldAnnouncementBar}
          bannerProps={pageProps.scrollingAnnouncementBar}
          countryFlags={countryFlags?.items}
        />
      )}
      <main className="fit">{children}</main>
      {pageProps?.doc?.show_service_usp_in_footer ? <DynamicServiceUSP serviceUSP={pageProps?.serviceUSP} /> : null}
      <DynamicFooter
        footer={pageProps?.footer}
        footerQuickLinks={pageProps?.footerQuickLinks}
        socialAndPayments={pageProps?.socialAndPayments}
        countryFlags={countryFlags?.items}
      />
      <ModalUI />
      {pageProps?.globalSettings?.klaviyo_list_id && pageProps?.globalSettings?.enable_newsletter_popup && (
        <NewsletterPopup
          listID={pageProps.globalSettings.klaviyo_list_id}
          content={{
            newsletter_popup_heading: pageProps?.globalSettings?.newsletter_popup_heading,
            newsletter_popup_supporting_text: pageProps?.globalSettings?.newsletter_popup_supporting_text,
            newsletter_popup_invalid_email_message: pageProps?.globalSettings?.newsletter_popup_invalid_email_message,
            newsletter_popup_general_error_message: pageProps?.globalSettings?.newsletter_popup_general_error_message,
            newsletter_popup_button_text_mobile: pageProps?.globalSettings?.newsletter_popup_button_text_mobile,
            newsletter_popup_button_text_desktop: pageProps?.globalSettings?.newsletter_popup_button_text_desktop,
            newsletter_popup_close: pageProps?.globalSettings?.newsletter_popup_close,
            newsletter_popup_successful_heading: pageProps?.globalSettings?.newsletter_popup_successful_heading,
            newsletter_popup_successful_supporting_text:
              pageProps?.globalSettings?.newsletter_popup_successful_supporting_text,
            newsletter_popup_successful_cta_text: pageProps?.globalSettings?.newsletter_popup_successful_cta_text,
            collect_mobile_number: pageProps?.globalSettings?.collect_mobile_number,
          }}
          timeout={pageProps?.globalSettings?.newsletter_popup_timeout || 15000}
        />
      )}
      <SidebarView miniCart={miniCart} prismicRef={prismicRef} />
      {miniCart?.loop_returns_enabled && <LoopReturnsModal />}
      <FeatureBar
        title="Preview mode is on."
        hide={!pageProps.preview}
        action={<Button onClick={exitPreviewMode}>Exit Preview</Button>}
        key="prismic-preview-mode"
      />
      {!isBot && <CountrySuggestion />}
      {locale === 'en-gb' && <GDPR buttonText={gdprData.buttonText} />}
      {/* {!!pageProps?.globalSettings?.enable_live_chat && <LiveChatButton />} */}
      <LiveChatButton />
    </div>
  )
}

export default Layout
